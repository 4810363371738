<template>
	<div class="bodily">
		<!-- <h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">产前的体型信息</h3> -->
		<div class="insert-title">{{$t('open.m1')}}</div>
		
		<el-form
			:model="formData"
			:rules="rules"
			inline
			ref="bodily"
		>
			<el-form-item
				:label="$t('open.t30')"
				prop="height"
			>
				<el-input
					v-model="formData.height"
					:placeholder="$t('open.f41')"
				></el-input>
				cm
			</el-form-item>
			
			<el-form-item
				:label="$t('open.t31')"
				prop="weight"
			>
				<el-input
					v-model="formData.weight"
					:placeholder="$t('open.f41')"
				></el-input>
				kg
			</el-form-item>
			
			<!-- <el-form-item
				label="胸围:"
				label-width="80px"
				prop="bust"
			>
				<el-input
					v-model="formData.bust"
					placeholder="请输入"
				></el-input>
				cm
			</el-form-item> -->
			
			<el-form-item
				:label="$t('open.t32')"
				prop="waist"
			>
				<el-input
					v-model="formData.waist"
					:placeholder="$t('open.f41')"
				></el-input>
				cm
			</el-form-item>
			
			<el-form-item
				:label="$t('open.t33')"
				prop="hip"
			>
				<el-input
					v-model="formData.hip"
					:placeholder="$t('open.f41')"
				></el-input>
				cm
			</el-form-item>
			
			<el-form-item
				:label="$t('open.t34')"
				prop="fat"
			>
				<el-input
					v-model="formData.fat"
					:placeholder="$t('open.t35')"
				></el-input>
				%
			</el-form-item>
			
			<!-- <h3 class="qjc-fts-16 qjc-ftw-500 qjc-c-dark">产后的体型信息</h3> -->
			<div class="insert-title margin-top-40" >{{$t('open.m2')}}</div>
			<el-form-item
				:label="$t('open.m3')"
				prop="preweek"
			>
				<el-input
					v-model="formData.preweek"
					:placeholder="$t('open.f41')"
				></el-input>
				<!-- 周 -->weeks
			</el-form-item>
			
			<el-form-item
				:label="$t('open.t31')"
				prop="preweight"
			>
				<el-input
					v-model="formData.preweight"
					:placeholder="$t('open.f41')"
				></el-input>
				kg
			</el-form-item>
			
			<!-- <el-form-item
				label="胸围:"
				label-width="80px"
				prop="prebust"
			>
				<el-input
					v-model="formData.prebust"
					placeholder="请输入"
				></el-input>
				cm
			</el-form-item> -->
			
			<el-form-item
				:label="$t('open.t32')"
				prop="prewaist"
			>
				<el-input
					v-model="formData.prewaist"
					:placeholder="$t('open.f41')"
				></el-input>
				cm
			</el-form-item>
			
			<el-form-item
				:label="$t('open.t33')"
				prop="prehip"
			>
				<el-input
					v-model="formData.prehip"
					:placeholder="$t('open.f41')"
				></el-input>
				cm
			</el-form-item>
			
			<el-form-item
				:label="$t('open.t34')"
				prop="prefat"
			>
				<el-input
					v-model="formData.prefat"
					:placeholder="$t('open.t35')"
				></el-input>
				%
			</el-form-item>
			
			<!-- <el-form-item class="qjc-wid-100 qjc-texta-c">
				<el-button @click="next" class="next qjc-fts-16 qjc-ftw-500">下一步</el-button>
				
			</el-form-item> -->
			
		</el-form>
		<div class="footer-btn">
			<el-button @click="handlePrev" class="prev qjc-fts-16 qjc-block">{{$t('open.t12')}}</el-button>
			<el-button @click="next" class="next qjc-fts-16 qjc-block">{{$t('open.t13')}}</el-button>
		</div>
	</div>
</template>

<script>
	let numberInput = function (value) {
		var price = '' + value;
		price = price
		    .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
		    .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
		    .replace('.', '$#$')
		    .replace(/\./g, '')
		    .replace('$#$', '.')
		    .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // 只能输入两个小数
		if (price.indexOf('.') < 0 && price != '') {
		    // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
		    price = parseFloat(price);
		}
		return price
	}
	export default{
		name: "bodily",
		data() {
			var heightVerify = (rule, value, callback) => {
				if(isNaN(numberInput(value)) || numberInput(value) < 130 || numberInput(value) > 200){
					callback(new Error(this.$t('open.t54')));
				}else{
					callback();
				}
			}
			var weightVerify = (rule, value, callback) => {
				if(isNaN(numberInput(value)) || numberInput(value) < 30 || numberInput(value) > 160){
					callback(new Error(this.$t('open.t55')));
				}else{
					callback();
				}
			}
			// var bustVerify = (rule, value, callback) => {
			// 	if(isNaN(numberInput(value)) || numberInput(value) < 40 || numberInput(value) > 150){
			// 		callback(new Error('胸围为40~150'));
			// 	}else{
			// 		callback();
			// 	}
			// }
			var waistVerify = (rule, value, callback) => {
				if(isNaN(numberInput(value)) || numberInput(value) < 40 || numberInput(value) > 150){
					callback(new Error(this.$t('open.t57')));
				}else{
					callback();
				}
			}
			var hipVerify = (rule, value, callback) => {
				if(value != '') {
					if(isNaN(numberInput(value)) || numberInput(value) < 60 || numberInput(value) > 165){
						callback(new Error(this.$t('open.t58')));
					}else{
						callback();
					}
				}
				if(value == '') {
					callback();
				}
				// if(isNaN(value) || value < 60 || value > 165){
				// 	callback(new Error('臀围为60~165'));
				// }else{
				// 	callback();
				// }
			}
			var fatVerify = (rule, value, callback) => {
				if(value != '') {
					if(isNaN(numberInput(value)) || numberInput(value) && numberInput(value) < 3 || numberInput(value) > 75){
						callback(new Error(this.$t('open.t59')));
					}else{
						callback();
					}
				}
				if(value == '') {
					callback();
				}
			}
			
			return {
				rules: {
					// 产前
					height: [
						{ required: true, message: this.$t('open.f39'), trigger: 'blur' },
						{ validator: heightVerify, trigger: 'blur' }
					],
					weight: [
						{ required: true, message: this.$t('open.f40'), trigger: 'blur' },
						{ validator: weightVerify, trigger: 'blur' }
					],
					// bust: [
					// 	{ required: true, message: '请填写您的胸围', trigger: 'blur' },
					// 	{ validator: bustVerify, trigger: 'blur' }
					// ],
					waist: [
						{ required: true, message: this.$t('open.f50'), trigger: 'blur' },
						{ validator: waistVerify, trigger: 'blur' }
					],
					hip: [
						{ required: false, message: this.$t('open.f51'), trigger: 'blur' },
						{ validator: hipVerify, trigger: 'blur' }
					],
					fat: [
						{ validator: fatVerify, trigger: 'blur' }
					],
					
					// 产后
					preweek: [
						{ required: true, message:  this.$t('open.m6'), trigger: 'blur' }
					],
					preweight: [
						{ required: true, message: this.$t('open.f40'), trigger: 'blur' },
						{ validator: weightVerify, trigger: 'blur' }
					],
					// prebust: [
					// 	{ required: true, message: '请填写您的胸围', trigger: 'blur' },
					// 	{ validator: bustVerify, trigger: 'blur' }
					// ],
					prewaist: [
						{ required: true, message: this.$t('open.f50'), trigger: 'blur' },
						{ validator: waistVerify, trigger: 'blur' }
					],
					prehip: [
						{ required: false, message: this.$t('open.f51'), trigger: 'blur' },
						{ validator: hipVerify, trigger: 'blur' }
					],
					prefat: [
						{ validator: fatVerify, trigger: 'blur' }
					]
				},
				
			}
		},
		props: ['formData'],
		methods: {
			handlePrev() {
				this.$emit('checkTab', 'Questions');
			},
			//下一步
			next() {
				this.$refs.bodily.validate((isSuc, err) => {
					if(isSuc){
						console.log(this.formData);
						this.$emit('checkTab', 'Puerpera');
					}
				});
			},
		}
	}
</script>

<style scoped>
	.bodily >>> .el-form-item__label{
		font-size: 14px;
		color: #777F8F;
		padding-right: 0;
		text-align: left;
	}
	.bodily >>> .el-form-item__label{
		font-size: 14px;
		color: #777F8F;
		padding-right: 10px;
		text-align: left;
		display: block;
		line-height: 30px;
	}
	.bodily >>> .el-input{
		width: 400px;
		height: 34px;
		line-height: 34px;
	}
	.bodily >>> .el-input input{
		width: 400px;
		height: 34px;
		font-size: 14px;
		line-height: 14px;
		padding: 9px 12px;
	}
	.bodily >>> .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
		display: none;
	}
	.bodily >>> .el-form-item__content{
		font-size: 14px;
	}
</style>
<style lang="scss" scoped>
	.bodily{
		padding-top: 50px;
		.insert-title {
			position: relative;
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #293033;
			line-height: 16px;
			padding-bottom: 15px;
			border-bottom: 1px solid #E5E8F5;
			margin-bottom: 32px;
			
			&::before{
				position: absolute;
				left: -24px;
				top: 0;
				content: '';
				width: 4px;
				height: 20px;
				background-color: #6883FB;
			}
		}
		
		.el-form-item{
			// margin: 24px 80px 0 0;
			// min-width: 500px;
			width: 48% !important;
		}
		.next-form-item {
			margin-right: 0 !important;
		}
		.footer-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 40px;
		}
				
		.next{
			padding: 0 10px;
			height: 34px;
			border-radius: 17px;
			border: none;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #fff;
		}
		.prev {
			padding: 0 10px;
			height: 34px;
			border-radius: 17px;
			border: 1px solid #6883FB;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #6883FB;
			background-color: #fff;
			margin-right: 24px;
		}
		.el-form{
			// padding-left: 60px;
			
			h3{
				margin-top: 40px;
			}
			
			.el-form-item{
				width: 48%;
				
				&:nth-child(2n+1) {
					padding-left: 40px;
				}
				// margin: 24px 0 0 0;
				
				&.qjc-wid-100{
					width: 100%;
				}
			}
		}
		
	}
	.margin-top-40 {
		margin-top: 40px;
	}
</style>
